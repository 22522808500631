import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter,Routes,Route} from 'react-router-dom';
import './index.css';
import {App, Header, Events, Socials, Blogs, Blog} from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter >
  
  <Routes>
  <Route path='/' element={
      <React.Fragment>
        <Header />
        <App />
      </React.Fragment>
      }/>

  <Route path='/events' element={
      <React.Fragment>
        <Header />
        <Events />
      </React.Fragment>
      }/>

  <Route path='/socials' element={
      <React.Fragment>
        <Header />
        <Socials />
      </React.Fragment>
      }/>

  <Route path='/blogs' element={
      <React.Fragment>
        <Header />
        <Blogs />
      </React.Fragment>
      }/>

<Route path='/blogs/:blogsID' element={
      <React.Fragment>
        <Header />
        <Blog />
      </React.Fragment>
      }/>

    

    </Routes>
    
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
