import logo from './logo.svg';
import './App.css';
import { Link } from 'react-router-dom';
import { StrictMode, useState, useRef, useEffect } from 'react';
import emailjs from '@emailjs/browser';

export function App() {
  console.log(window.innerWidth)
  return (
    <article style={{marginTop:'30px'}}>
      <img width={'95%'} style={{marginTop:'35px'}} src={require('./images/ALAS30TH.png')} />
      <h1>Welcome to the Alliance of Latin American Students</h1>
      <p>
      The Alliance of Latin American Students (ALAS) is a Latin American Community in Queens College that focuses on <i>La Familia</i> (Family) by providing a fun social environment while maintaining a Safe Space for all. We like to unwind while sharing our experiences of Latin American Culture to each of our members with Events and Activities for all to enjoy.
      </p>
      <h1>Meet the Eboard</h1>

      <section className='EboardMember'>
      <img width={250} className='EboardMemberImage' src={require('./images/Josh.webp')} />
        <section className='EboardMemberDetails'>
          <h2>President <br /> Joshua Martinez</h2>
          <p className='EboardMemberDesc'>Hi my name is Joshua Martinez! I’m a Senior majoring in Economics and minoring in Urban Planning. I love biking, hiking and just being outdoors and active. I play video games and enjoy listening to Music on my free time. I joined ALAS Fall of 2022, I wanted to improve my social skills  and meet new people, and I will never regret joining ALAS because it has truly became my second home away from home in QC along with friends I can call my familia!</p>
        </section>
      </section>

      <section className='EboardMember'>
      <img width={250} className='EboardMemberImage' src={require('./images/Mia.webp')} />
        <section className='EboardMemberDetails'>
          <h2>Vice President <br /> Mia Castillo</h2>
          <p className='EboardMemberDesc'>Hola todos 🩷, My name is Mia. I am the vice president of ALAS 2024🩷 I’m Ecuadorian and practicing getting my cosmetology license. I’m in my sophomore year with Psychology and Photography major. Fun fact! My cousin is an ALAS alumnus back in 2008. So I guess it kind of runs in the family 😌. I joined ALAS because I felt safe and welcomed.So many laughs that I had with them, and allowed me to build long-lasting relationships with others. So if you're considering joining, don't be afraid to come talk to me. Xo</p>
        </section>        
      </section>

      <section className='EboardMember'>
        <img width={250} className='EboardMemberImage' src={require('./images/Adrianna.webp')} />
        <section className='EboardMemberDetails'>
          <h2>Secretary <br /> Adrianna Mejias</h2>
          <p className='EboardMemberDesc'>Hello I'm Adri! I'm in my second year for a Biology BA and one of my favorite things to do is learning new things about other people’s cultures. I'm from Puerto Rico and Ecuador, but sadly a no sabo kid... Being the secretary of ALAS allows me to help students find fun experiences in a stress relieving environment, while also appreciating the Latin American community and culture in QC. This club led me to make new connections that I would have never expected to make on my own and I value each of our members so much</p>
        </section>
      </section>

      <section className='EboardMember'>
        <img width={250} className='EboardMemberImage' src={require('./images/Gabe.webp')} />
        <section className='EboardMemberDetails'>
          <h2>Treasurer <br /> Gabriel Jatap</h2>
          <p className='EboardMemberDesc'>Hello, my name is Gabriel Jatap or Gabe for short. I am a Junior majoring in Design. I do portraits of friends inspired by anime or shows and gained interest in 3D printing, creating replicas from anime/movies and tv shows. ALAS welcomed me into their community and showed me how a real family is when I was lost and needed support. To me ALAS is a great place to be heard and is open minded. They wouldn’t judge you based on the opinions of others. When in need of guidance, they will help as best they can as they see everyone being a part of their big family. I became the Treasurer for ALAS because I want to return all the help and understanding they have given me</p>
        </section>
      </section>
    </article>
  );
}

export function Socials(){
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_r8ylhe7', 'template_twe166v', form.current, 'E_ZdVnffvk9bcb0Be')
      .then((result) => {
          console.log(result.text);
          alert('Your email has been sent',form.current[0],'!')
      }, (error) => {
          console.log(error.text);
          alert('An error occured tr')
      });
  };

  return(
    <article>
      <h1>Socials</h1>
      
      <p>Want to connect with us? Be sure to follow us in our Instagram and join our discord server to stay up to date on our events and keep in touch with other ALAS members!</p>
      <section className='SocialLinks'>
      <a href='https://www.instagram.com/qcalas' target='_blank'><img width={150} src={require('./images/Instagram.webp')} alt='Instagram' /></a>
      </section>
      <h2 style={{paddingTop:'20px'}}>Thoughts, Comments, Concerns?</h2>
      <p>Send us and email and we'll get right back to you</p>

      <form ref={form} onSubmit={sendEmail}>
        <input type='text' name='from_name' placeholder='  Name' required />
        <input type='email' name='email' placeholder='  Email' required />
        <textarea name='message' placeholder=' Write your message over here' required/>
        <button type='submit'>Send</button>
      </form>
    </article>
  )
}

export function Blogs(){
  const [data,setData] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [filteredBlogs, setFilteredBlogs] = useState("")

  useEffect(() => {
    setLoading(true)
		fetch(
			"https://sheets.googleapis.com/v4/spreadsheets/160UUpkBhZEmns4oVcobBVM2hVzH7OELl_uHgGrdXYK4/values/Sheet2?alt=json&key=AIzaSyDvlO1CKa7-hkcvv4BVGwvFzrkOMXWX5rc"
		).then(function(response){ return response.json();
		})
		.then(setData)
		.then(() => {
      console.log(data.values)
      setLoading(false)
    })
      	.catch(setError)
	}, []);

  if(loading) return (
    <article className='loading'>
      <p>Loading...</p>
    </article>
  );
  if(error) return <article>
    An Error has occured when loading blogs. Please try again
    </article>
  if(!data) return null;

  const filterBlogs = (e) =>{
    
    const filter = data.values.slice(1).filter((blog) => {
      return blog[1].toLowerCase().includes(e.target.value.toLowerCase())
    })
    setFilteredBlogs(filter)
    console.log(filteredBlogs)
  }

  return(
    <article>
      
      <h1>Blogs</h1>
      <section className='Blogs'>
        {
          data.values.slice(1).map((blog, index) => (
            <Link to={`/blogs/${index+1}`} className='Blog' key={index}>
              <img width={200} src={blog[4]} alt='blogImage' />
              <section className='BlogDescription'>
                <h3 className='BlogTitle'>{blog[1]}</h3>
                
                <small>By {blog[2]}</small>
                <p dangerouslySetInnerHTML={{__html:blog[3].substring(0,90)+'...'}}></p>
                <small>{blog[0]}</small>
              </section>
            </Link>  
          ))
        }
      </section>
      <h2 style={{marginTop:'65px'}}>Have a <span style={{fontStyle:'italic', color:'rgb(253, 253, 122)'}}>personal experience</span> you want to share?</h2>
      <p>Feel free to send a personal letter to an Eboard Member to express yourself. They will be in charge of verifying and uploading your post</p>
      <h2 style={{marginTop:'65px'}}>Search for Blog</h2>
      <input className='search' onChange={filterBlogs} type='text' placeholder='  Search Blog...' />
      <section className='FilteredBlogs'>
        {
          filteredBlogs ?
          filteredBlogs.map((blog, index) => (
            <Link to={`/blogs/${blog[5]}`} className='FilteredBlog' key={index}>
              <img width={200} src={blog[4]} alt='blogImage' />
              <section className='FilteredBlogDescription'>
                <h3 style={{marginBottom:'10px'}} className='FilteredBlogTitle'>{blog[1]}</h3>
                
                <small >By {blog[2]}</small>
                <p dangerouslySetInnerHTML={{__html:blog[3].substring(0,130)+'...'}}></p>
                <small>{blog[0]}</small>
              </section>
            </Link>  
          ))
          :
          <section style={{margin: '50px 0 50px 0'}}>
            <p>Searched Blogs will be here!</p>
          </section>
        }
      </section>
    </article>
  )
}

export function Blog(){
  const [data,setData] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true)
		fetch(
			"https://sheets.googleapis.com/v4/spreadsheets/160UUpkBhZEmns4oVcobBVM2hVzH7OELl_uHgGrdXYK4/values/Sheet2?alt=json&key=AIzaSyDvlO1CKa7-hkcvv4BVGwvFzrkOMXWX5rc"
		).then(function(response){ return response.json();
		})
		.then(setData)
		.then(() => {
      console.log(data.values)
      setLoading(false)
    })
      	.catch(setError)
	}, []);


  if(loading) return (
    <article className='loading'>
      <p>Loading...</p>
    </article>
  );
  if(error) return <article>
    An Error has occured when loading this blog. Please try again
    </article>
  if(!data) return null;
  
  const linkID = window.location.href.split('/')[4]
  console.log(window.location.href.split('/')[4])
  return(
    // <p style={{color:'red'}}>
    //   {data.values[linkID]}
    // </p>
    <article>
      {
        console.log(data.values[linkID])
      }
      <img width={'90%'} src={data.values[linkID][4]} alt='blog' />
      <h1>{data.values[linkID][1]}</h1>
      <small>Written By: {data.values[linkID][2]}</small>
      <p style={{textAlign:'left', width:'90%',margin:'0 auto'}} dangerouslySetInnerHTML={{__html:data.values[linkID][3]}}></p>
    </article>
  )
}

export function Events(){
  const [data,setData] = useState("");
  const [filteredEvents, setFilteredEvents] = useState("");
  const [loadedData, setLoadedData] = useState("")
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const date = new Date()

  useEffect(() => {
    setLoading(true)
		fetch(
			"https://sheets.googleapis.com/v4/spreadsheets/160UUpkBhZEmns4oVcobBVM2hVzH7OELl_uHgGrdXYK4/values/Sheet1?alt=json&key=AIzaSyDvlO1CKa7-hkcvv4BVGwvFzrkOMXWX5rc"
		).then(function(response){ return response.json();
		})
		.then(setData)
		.then(() => {
      console.log(data.values)
      setLoading(false)
    })
      	.catch(setError)
	}, []);

  if(loading) return (
    <article className='loading'>
      <p>Loading...</p>
    </article>
  );
  if(error) return <article>
    An Error has occured when loading blogs. Please try again
    </article>
  if(!data) return null;

  const filterEvents = (e) =>{
    
    const filter = data.values.slice(1).filter((event) => {
      return event[0].toLowerCase().includes(e.target.value.toLowerCase())
    })
    setFilteredEvents(filter)
    console.log(filteredEvents)
  }

  return(
    <article>
      
      <h1>Events</h1>

      <section className='Events'>
        {
          data.values.slice(1).reverse().map((event, index) => (
            <section className='Event' key={index}>
              <img className='EventImage' src={event[1]} alt='eventImage' />
              <section className='EventDescription'>
                <h3 style={{fontSize:'36px'}} className='EventTitle'>{event[0]}</h3>
                <p style={{fontSize:'22px', marginTop:'10px'}}>{event[3]}
                </p>
                <small>{event[4]}</small>
                <section className='EventLinks'>
                {Date.parse(event[2]) > date.getTime() ? 
                <a href={event[5]}>Register Now</a>
                : 
                  ''
                }
                {
                  event[6] ?
                  <a href={event[6]}>View Event</a>
                  :
                  ''
                }
                </section>
              </section>
            </section>  
          ))
        }
      </section>
      <h2>Search Events</h2>
      <input className='search' onChange={filterEvents} type='text' placeholder='  Search Event...' />
      <section className='FilteredEvents'>
        {
          filteredEvents ?
          filteredEvents.map((event, index) => (
            <section className='FilteredEvent' key={index}>
              <img width={200} src={event[1]} alt='eventImage' />
              <section style={{margin:'20px'}} className='FilteredEventDescription'>
                <h3 className='EventTitle'>{event[0]}</h3>
                <p style={{marginTop:'2px'}}>{event[3]}
                </p>
                <small>{event[4]}</small>
                <section className='FilteredEventLinks'>
                {Date.parse(event[2]) > date.getTime() ? 
                <a href={event[5]}>Register Now</a>
                : 
                  ''
                }
                {
                  event[6] ?
                  <a href={event[6]}>View Event</a>
                  :
                  ''
                }
                </section>
              </section>
            </section> 
          ))
          :
          <section style={{margin: '50px 0 50px 0'}}>
            <p>Searched Events will be here!</p>
          </section>
        }
      </section>
    </article>
  )
}

export function Header(){
  return(
    <header>
      <img className='headerImage' style={{marginLeft:'10px'}} src={require('./images/alas.png')} height={60} alt='apic' />
      <section></section>
      <Link className='tab' to={'/'}>About</Link>
      <Link className='tab' to={'/events'}>Events</Link>
      <Link className='tab' to={'/blogs'}>Blogs</Link>
      <Link className='tab' to={'/socials'}>Social</Link>
    </header>
  )
}

export default App;
